.react-joyride__tooltip {
   background-color: rgba(88, 63, 153, 1) !important;
   color: #fff !important;
   border-radius: 5px;
}

#react-joyride-step-0 .react-joyride__tooltip {
   background-color: #fff !important;
   color: #535B66 !important;
   text-align: center;
}

#react-joyride-step-0 .react-joyride__tooltip.topbar button {
   color: #535B66 !important;
}

#react-joyride-step-0 .react-joyride__tooltip h2,
#react-joyride-step-3 .tooltip--mtpSettings h2 {
   color: #212427 !important;
}

#react-joyride-step-0 .react-joyride__tooltip .MuiButtonBase-root {
   background-color: rgba(88, 63, 153, 1) !important;
   color: #fff !important;
   margin: 0 auto
}

.__floater__arrow svg polygon {
   fill: rgba(88, 63, 153, 1) !important;
}

#react-joyride-step-0 .react-joyride__tooltip .topbar .steps {
   visibility: hidden;
}

#react-joyride-step-0 .react-joyride__tooltip .topbar button {
   color: #555 !important;
}

.leadSelf-url .__floater__open[x-placement="top-start"] .__floater__arrow {
   text-align: center;
}

.leadSelf-url .__floater__open[x-placement="top-start"] .__floater__arrow span {
   position: relative !important;
   left: auto !important;
}

.react-joyride__overlay {
   height: 3000px !important
}

#react-joyride-step-3 .tooltip--mtpSettings {
   background-color: #fff !important;
   color: #535B66 !important;
   text-align: center;
}

.leadSelfSettings.joyride-active .MuiBackdrop-root {
   display: none !important;
}

#react-joyride-step-3 .tooltip--mtpSettings .topbar button {
   color: #535B66 !important;
}

.joyride-active.mtpSettings-url .MuiDialog-root {
   display: none !important;
}

/* .leadSelfSettings.joyride-active .MuiDialog-root{position: relative!important;} */
#react-joyride-step-3 .tooltip--mtpSettings __floater__open {
   background-color: #fff !important;
}

#react-joyride-step-3 .tooltip--mtpSettings svg#Layer_1 {
   margin-left: 15px;
}

.leadSelfSettings .react-joyride__spotlight {
   position: relative !important;
   ;
}

.leadSelfSettings.joyride-active .lsbuttons {
   pointer-events: none;
}

@media only screen and (max-width: 1024px) {
   .react-joyride__overlay {
      height: 6000px !important
   }
}

.teamDownload.MuiCardHeader-action {
   display: none !important;
}

.MuiAccordion-root.downloadacc .MuiCollapse-vertical {
   height: auto !important;
   min-height: 'auto!important';
   visibility: visible !important;
}

.MuiAccordion-root.downloadacc .MuiAccordionSummary-content {
   color: rgba(88, 63, 153, 1) !important;
   break-inside: avoid;
}

.MuiAccordion-root.downloadacc .MuiAccordionSummary-expandIconWrapper {
   display: none !important;
}

.MuiAccordion-root.downloadacc.Web-Print .MuiInputBase-input {
   display: block !important;
   color: #555 !important
}

.MuiAccordion-root.downloadacc .MuiInputBase-input {
   display: none !important;
   pointer-events: none, ;
   opacity: 0.5;
   /* Make it look disabled */
   /* Optional: change background color */
   white-space: pre-wrap !important;
   /* Allow wrapping of text */
   overflow-wrap: break-word !important;
   /* Handle long words */
   font-size: 12px !important;
   color: rgb(247, 240, 240) !important;
}

.MuiAccordion-root.downloadacc .WebOnly {
   display: none !important
}

.MuiAccordion-root.downloadacc .PrintOnly {
   display: block !important;
   background-color: rgba(230, 230, 230, 0.5) !important;
   color: #555;
   padding: 15px;
   border-radius: 15px;
   white-space: pre-line!important;
}

.MuiAccordion-root .PrintOnly {
   display: none !important
}

.MuiAccordion-root.downloadacc .MuiInputBase-input[aria-hidden="true"] {
   display: none !important
}
.downloadContent{ display: none }
  .downloaded.dhide{ display: none }
   .downloaded.downloadContent{ display: block }
   .downloaded.downloadContent .downimage img{width: auto;}
   .downloadacc .graphdown{width:800px}

@media print {
   .MuiAccordion-root.downloadacc .MuiInputBase-input[aria-hidden="true"] {
      display: none !important
   }
   .MuiAccordion-root.downloadacc .MuiCollapse-vertical {
      height: auto !important;
      min-height: 'auto!important';
      visibility: visible !important;
   }
   
   .MuiAccordion-root.downloadacc .MuiAccordionSummary-content {
      color: rgba(88, 63, 153, 1) !important;
      break-inside: avoid;
   }
   
   .MuiAccordion-root.downloadacc .MuiAccordionSummary-expandIconWrapper {
      display: none !important;
   }
   .MuiAccordion-root.downloadacc .MuiAccordionSummary-expandIconWrapper {
      display: none !important;
   }
 .downloadContent{ display: none }
  .downloaded.dhide{ display: none }
   .downloaded.downloadContent{ display: block }
   .MuiAccordion-root.downloadacc .MuiInputBase-input {
      pointer-events: none, ;
      opacity: 0.5;
      /* Make it look disabled */
      background-color: #f0f0f0 !important;
      /* Optional: change background color */
      white-space: pre-wrap !important;
      /* Allow wrapping of text */
      overflow-wrap: break-word !important;
      /* Handle long words */
      height: auto !important;
      /* Let the height adjust */
      font-size: 14px !important;
      overflow: auto !important;
      width: 95vw !important;
      display: block !important;

   }

   .MuiAccordion-root.downloadacc .MuiInputBase-input[aria-hidden="true"] {
      display: none !important;
      height: 100% !important;
      /* Let the height adjust */
      font-size: 14px !important;
      overflow: auto !important;
      width: 95vw !important;
      display: block !important;
   }

   .downloadacc .MuiAccordion-root {
      margin-top: 10px !important;box-shadow: none;
   }

   .MuiAccordion-root.downloadacc{box-shadow: none;}
   .MuiAccordion-root.downloadacc .MuiAccordionSummary-root{page-break-after:avoid}
   .MuiAccordion-root.downloadacc .acspace {
      padding-left: 0;
      padding-right: 0;
   }
   
.downloadPrint.cc-grid .MuiGrid-root{flex-basis:50%}
.PaperShadowPrint.cc-paper .MuiPaper-root,.PaperShadowPrint.cc-paper .MuiCard-root{box-shadow: none!important;border: none!important;}
.PaperShadowPrint.cc-paper .strapi-space .MuiAccordionDetails-root{padding-left:0; padding-right: 0;padding-bottom: 0;}
.PaperShadowPrint.cc-paper .strapi-space .MuiAccordionDetails-root ul{margin-top: 0;}
   #topNavWidth.hidtopnam {
      display: none !important;
   }

   .high-item {
      background-color: #F42426 !important;
      webkit-print-color-adjust: exact;
      print-color-adjust: exact
   }

   .low-item {
      webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      background-color: #F1A11F !important;
   }

   .medium-item {
      webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      background-color: #0073B6 !important;
   }

   .default-item {
      webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      background-color: #F42426 !important;
   }

}

.rlegend{webkit-print-color-adjust: exact;
   print-color-adjust: exact;background-color: #F42426!important}
   .olegend{webkit-print-color-adjust: exact;
       print-color-adjust: exact;background-color: #EFA70D!important}
   .glegend{webkit-print-color-adjust: exact;
       print-color-adjust: exact;background-color: #9ECA3D!important}
       .tips-FFB31E,.tips-9ECA3D,.tips-37B7EA{webkit-print-color-adjust: exact;
         print-color-adjust: exact;padding:10px!important;word-wrap: break-word;
         word-break: break-word;}
       .tips-FFB31E{background-color:#FFB31E55!important;}
 .tips-37B7EA{background-color:#37B7EA55!important;}
 .tips-9ECA3D{background-color:#9ECA3D55!important;}
     .compare{flex-direction: row!important;}
     .tips-FFB31E svg path{fill:#FFB31E;}
.tips-37B7EA svg path{fill:#37B7EA;}
.tips-9ECA3D svg path{fill:#9ECA3D}
.MuiAccordion-root.downloadacc .graphwidth{width: 800px;}
.MuiAccordion-root.downloadacc .MuiInputBase-input {
   pointer-events: none, ; 
   opacity: 0.5;
   /* Make it look disabled */
   background-color: #f0f0f0 !important;
   /* Optional: change background color */
   white-space: pre-wrap !important;
   /* Allow wrapping of text */
   overflow-wrap: break-word !important;
   /* Handle long words */
   height: auto !important;
   /* Let the height adjust */
   font-size: 14px !important;
   overflow: auto !important;
   width: 95vw !important;
   display: block !important;

}

.MuiAccordionSummary-content.Mui-expanded {
   break-inside: avoid;
}

.avoid-page-break {
   break-inside: avoid;
   /* Prevents the element from being split between pages */
}

.page-break {
   page-break-before: always;
   /* Forces a break before the element */
}

.page-break-after {
   page-break-after: always;
   /* Forces a break after the element */
}